import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Please `}<a parentName="p" {...{
        "href": "mailto:spencer@avironsoftware.com"
      }}>{`contact me`}</a>{` if you’re interested in having me speak at your event.`}</p>
    <h4>{`Upcoming`}</h4>
    <table className="table">
	<tr>
		<th>Description</th>
		<th>Date</th>
		<th>Event</th>
		<th>Location</th>
	</tr>
	<tr>
		<td>Using Immutable Data Structures in C# and .NET</td>
		<td>January 2020</td>
		<td><a href="https://www.ndc-london.com/">NDC London</a></td>
		<td>London, England</td>
	</tr>
    </table>
    <h4>{`Notable past events`}</h4>
    <table className="table">
	<tr>
		<th>Description</th>
		<th>Date</th>
		<th>Event</th>
		<th>Location</th>
	</tr>
	<tr>
		<td>Workshop: Building APIs in ASP.NET Core and C#</td>
		<td>September 2019</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Workshop: Building APIs in ASP.NET Core and C#</td>
		<td>September 2019</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td> Real World Expression Trees in C#</td>
		<td>June 2019</td>
		<td><a href="https://ndcoslo.com/">NDC Oslo</a></td>
		<td>Oslo, Norway</td>
	</tr>
	<tr>
		<td>Workshop: Building RESTful APIs with ASP.NET Core and C#</td>
		<td>May 2019</td>
		<td><a href="https://ndcminnesota.com/">NDC Minnesota</a></td>
		<td>Minneapolis, MN, USA</td>
	</tr>
	<tr>
		<td> Real World Expression Trees in C#</td>
		<td>May 2019</td>
		<td><a href="https://ndcminnesota.com/">NDC Minnesota</a></td>
		<td>Minneapolis, MN, USA</td>
	</tr>
	<tr>
		<td> Real World Expression Trees in C#</td>
		<td>February 2019</td>
		<td><a href="https://ndcporto.com/">NDC Porto</a></td>
		<td>Porto, Portugal</td>
	</tr>
	<tr>
		<td> Using Immutable Data Structures in C#</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td> The Hello World Show LIVE</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td> Workshop: Building Single Page Apps Using React and ES6</td>
		<td>September 2018</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>Your JavaScript Needs Types</td>
		<td>July 2018</td>
		<td><a href="https://kcdc.info/">KCDC</a></td>
		<td>Kansas City, MO, USA</td>
	</tr>
	<tr>
		<td> Versioning and Maintaining Your REST API in ASP.NET Core</td>
		<td>July 2018</td>
		<td><a href="https://kcdc.info/">KCDC</a></td>
		<td>Kansas City, MO, USA</td>
	</tr>
	<tr>
		<td>Deconstructing TypeScript's Type System</td>
		<td>June 2018</td>
		<td><a href="https://ndcoslo.com">NDC Oslo</a></td>
		<td>Oslo, Norway</td>
	</tr>
	<tr>
		<td>The Hello World Show LIVE (<a href="https://www.youtube.com/watch?v=66fcoX7kt7Y">video</a>)</td>
		<td>January 2018</td>
		<td><a href="https://ndclondon.com">NDC London</a></td>
		<td>London, England</td>
	</tr>
	<tr>
		<td>Your JavaScript Needs Types (<a href="https://www.youtube.com/watch?v=xVo0M_vVyxw">video</a>) (<a href="https://github.com/schneidenbach/YourJavaScriptNeedsTypes">slides/info</a>)</td>
		<td>September 2017</td>
		<td><a href="http://devconf.pl/">DevConf Poland</a></td>
		<td>Kraków, Poland</td>
	</tr>
	<tr>
		<td>An Opinionated, Maintainable REST API Architecture for ASP.NET Core (<a href="https://www.youtube.com/watch?v=CH9VEeV-zok">video</a>) (<a href="https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/">slides/info</a>)</td>
		<td>June 2017</td>
		<td><a href="https://ndcoslo.com">NDC Oslo</a></td>
		<td>Oslo, Norway</td>
	</tr>
	<tr>
		<td>Never RESTing – RESTful API Best Practices using ASP.NET Web API (<a href="https://www.youtube.com/watch?v=x0yNKU-tz1Y">video</a>)
(<a href="https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/">slides/info</a>)</td>
		<td>January 2017</td>
		<td><a href="https://ndclondon.com">NDC London</a></td>
		<td>London, England</td>
	</tr>
    </table>
    <p>{`For my full past speaking history, click `}<a parentName="p" {...{
        "href": "https://schneids.net/full-speaking-history/"
      }}>{`here`}</a>{`.`}</p>
    <h4>{`Current talks`}</h4>
    <h5><a parentName="h5" {...{
        "href": "https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/"
      }}>{`Never RESTing – RESTful API Design Best Practices Using ASP.NET Web API`}</a></h5>
    <p>{`Designing and building RESTful APIs isn’t easy. On its surface, it may seem simple – after all, we’re only marshaling JSON back and forth over HTTP right? However, that’s only a small part of the equation. There are many things to keep in mind while building the systems that act as the key to your system.`}</p>
    <p>{`In this session, we’ll delve into several best practices to keep in mind when designing your RESTful API. We’ll discuss authentication, versioning, controller/model design, and testability. We’ll also explore the do’s and don’t’s of RESTful API management so that you make sure your APIs are simple, consistent, and easy-to-use. Finally, we’ll discuss the importance of documentation and change management.`}</p>
    <p>{`The session will show examples using ASP.NET Web API and C#. However, this session will benefit anyone who is or might be working on a RESTful API.`}</p>
    <h5>{`Versioning and Maintaining Your REST API in ASP.NET Core`}</h5>
    <p>{`When tasked with making a REST API for your system, you'll inevitably have to think about versioning. One of the most difficult parts of API maintenance, versioning is an essential conversation to have about the future of your API, even before you hit File -> New Project. Do you version in the URL (v1/api/whatever)? Do you use header versioning? Should I update my production API in place?`}</p>
    <p>{`In this session, Spencer will provide several strategies and real world examples of API versioning done right... and wrong. We'll discuss versioning strategies such as header versioning and URL versioning, as well as best practices on how to intelligently version your REST API without tearing your hair out... or making your consumers tear their hair out.`}</p>
    <h5><a parentName="h5" {...{
        "href": "https://schneids.net/deconstructing-typescripts-type-system/"
      }}>{`Deconstructing TypeScript's Type System`}</a></h5>
    <p>{`TypeScript isn’t just a strongly-typed replacement for JavaScript – it’s a full language with lots of modern features, including a cutting-edge type system. This awesome type system is often overlooked in favor of other features such as decorators and async/await, but is one of the best reasons to use TypeScript.`}</p>
    <p>{`In this session, Spencer will discuss the advanced type features of TypeScript, including intersection types, type guards, nullable types, type aliases, string literals, and discriminated unions. We’ll also discuss ways to take full advantage of TypeScript’s compile-time checking to ensure your code is clean and safe.`}</p>
    <h5><a parentName="h5" {...{
        "href": "https://schneids.net/never-resting-restful-api-best-practices-using-asp-net-web-api/"
      }}>{`An Opinionated, Maintainable REST API Architecture for ASP.NET Core`}</a></h5>
    <p>{`Writing a brand-new RESTful API using ASP.NET has never been easier, or so you’ve been led to believe. Create a project, write a few models, and scaffold – instant API for your website! While convenient, this leads to a lot of pain as your API scales. Testability and maintainability become more and more difficult as your codebase continues to expand. Eventually, you’re left with a huge mess of code and zero separation of concerns.`}</p>
    <p>{`In this session, Spencer will provide a highly-opinionated and easy-to-learn pattern for creating scalable, maintainable APIs powered by ASP.NET Core. Attendees will learn about dependency injection, validating requests, executing requests via services, error handling, and versioning strategies to make sure your API lasts for the long haul.`}</p>
    <h5><a parentName="h5" {...{
        "href": "https://github.com/schneidenbach/YourJavaScriptNeedsTypes"
      }}>{`Your JavaScript Needs Types`}</a></h5>
    <p>{`What’s one thing Slack, Facebook, and Google have in common? They’ve all embraced types in their JavaScript! Typed JavaScript is meeting lots of resistance from folks who don’t believe their JavaScript needs it, but many projects are transitioning to type systems such as TypeScript or Flow for better long-term maintainability and bug reduction. `}</p>
    <p>{`In this session, Spencer will break down why types in your JavaScript will make you a better JS developer. We’ll explore the benefits brought on by typed JavaScript, such as better tooling and ease of refactoring. Finally, we’ll discuss how you can introduce typing to your JavaScript in projects small to large, new and old.`}</p>
    <h5><a parentName="h5" {...{
        "href": "https://github.com/schneidenbach/CSharpExpressionTreesInTheRealWorld"
      }}>{`Using C# Expression Trees in the Real World`}</a></h5>
    <p>{`Entity Framework is the de facto data access standard in .NET, and for good reason – its simplicity and power enables anyone to quickly write and execute strongly-typed queries using LINQ. Have you ever wondered how Entity Framework translates even the most complicated of LINQ queries to SQL? The answer is simple – expression trees.`}</p>
    <p>{`Used by many libraries we know and love (including EF, AutoMapper, and NHibernate), expression trees provide a lot of power in an easy-to-use API surface. In this session, Spencer will unravel the mysteries of expression trees. Attendees will learn what expression trees are, how to create them, and how libraries like Entity Framework and AutoMapper use them to perform routine tasks.`}</p>
    <h5>{`Using Immutable Data Structures in C#`}</h5>
    <p>{`Immutable objects - aka objects whose properties and fields can't change after instantiation - are one of the fundamental pillars of functional programming. Yet, it also has other great uses in classically object-oriented languages such as C#. Immutability can help make your code cleaner, easier to reason about, and reduce runtime errors. But how can you do that without making it feel like you're fighting against the typically-mutable C# and the entire .NET Framework?`}</p>
    <p>{`In this session, Spencer will discuss immutability and how you can apply it in your day-to-day as a .NET/C# developer. We'll go over immutable objects, immutable collections, and how you can refactor your code to successfully apply immutability to your exisitng codebase. Spencer will also discuss the implications of introducing immutability to your code, both positive and negative.`}</p>
    <h5>{`Three Rewrites, One Startup - An Architect’s Journey`}</h5>
    <p>{`Picture this: you're a senior engineer at your company who, one day, is thrust into the architect role. Suddenly, you're having to think about things that you've never thought about - what's the difference between a microservice and a service bus? How do I design a scalable system for tomorrow without overengineering today?`}</p>
    <p>{`Better yet, if you're at a startup, do all that - and still be responsible for code reviews, development, and coaching of junior devs. How can a fledgling architect possibly "do it all" - and more importantly, do it well?`}</p>
    <p>{`Luckily, someone has gone down that path (and is still going)... and he's ready to share his story.`}</p>
    <p>{`Attend Spencer's talk and hear about how he, as a senior engineer, had to grow into the architect role. We'll discuss the problems faced, the critical "decision points" he made from an architecture standpoint, the results of those choices, and how he helped guide the business through three architectural rewrites - all in a span of four years!`}</p>
    <h5>{`Evolution of an Architecture - Going from one-time Project to Million Dollar Startup`}</h5>
    <p>{`What happens when you take a small, one-off project for a customer and say to yourself, "this could be a product"? Besides the excitement of possibly building a whole new company, you're faced with a slew of technical choices. What do you do with the old code base? What do you have to build to make sure it scales properly, both from a performance and a developer perspective? Where do you invest your time in building something for the long haul and where do you cut corners, faced with having to balance multiple hats? How do you evaluate the possibility of the - gasp - rewrite?`}</p>
    <p>{`In this talk, Spencer explores the evolution of the architecture at his startup. We'll discuss the decisions he faced as senior-dev-turned-architect and how he made the choices he made - both good and bad. From technical blunders to big impacts, this is guaranteed to be a wild ride!`}</p>
    <h5>{`Workshop: Building Web Apps with ASP.NET Core`}</h5>
    <p>{`ASP.NET Core is the latest web framework offering from Microsoft. Open source and cross-platform, ASP.NET Core is the best web development platform for building web and cloud based applications on Windows, Mac and Linux.`}</p>
    <p>{`This workshop is fully hands-on with guided exercises designed to get you productive in ASP.NET Core quickly. Whether you’re new to ASP.NET or are a seasoned ASP.NET developer, this workshop will have something for everyone. We'll discuss creating new projects, services, dependency injection, middleware, logging and diagnostics, Razor views, web APIs, and single page apps in this comprehensive jump-start to ASP.NET Core.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      